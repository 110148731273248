import React, { useEffect, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { getFormValues } from "../../../utils/helper";
import { useSnackbar } from "notistack";
import axios from "axios";
import { Backdrop, CircularProgress, Divider } from "@mui/material";
import { CHATBOT_CORE_API_URL } from "../../../constants/URLS";
import social_icons from "../../../assets/images/social_icons.png";
import CustomHeading from "../../../components/CustomHeading";
import Navbar from "../../../Layout/Navbar";
import CustomLabel from "../../../components/CustomLabel";
import CustomTextInput from "../../../components/CustomTextInput";
import CustomButton from "../../../components/CustomButton";
import { useDispatch } from "react-redux";
import { setAccessToken, setRefreshToken } from "../store/authSlice";
import { getItemFromLocalStorage } from "../../../services/localStorage";

const Login = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  const access_token = getItemFromLocalStorage("access_token");

  const location = "/widget-settings";

  const navigate = useNavigate();
  const handleSubmitForm = async (event) => {
    event.preventDefault();
    setLoading(true);

    const values = getFormValues(event);
    let formValues = {
      username: values?.email,
      password: values?.password,
    };

    try {
      const response = await axios.post(
        `${CHATBOT_CORE_API_URL}users/login`,
        formValues,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );

      dispatch(setAccessToken(response?.data?.access_token));
      dispatch(setRefreshToken(response?.data?.refresh_token));

      localStorage.setItem("access_token", response?.data?.access_token);
      localStorage.setItem("refresh_token", response?.data?.refresh_token);

      enqueueSnackbar("Successfully login!", { variant: "success" });
      navigate("/widget-settings");
    } catch (error) {
      console.log("Error", error?.response?.data?.detail);

      enqueueSnackbar(`${error?.response?.data?.detail}`, { variant: "error" });
    } finally {
      setLoading(false);
    }
  };

  if (access_token) return <Navigate to={location}></Navigate>;

  return (
    <div className="h-[100vh] flex flex-col justify-start items-center">
      <Navbar showHeaderRight={false} />

      <div className="flex w-[100%] items-center justify-center h-[100%]">
        <div className=" bg-white flex max-w-[80%] sm:max-w-[60%] md:max-w-[60%]  lg:max-w-[30%%] xl:max-w-[30%] flex-col justify-center px-6 py-8 lg:px-8 text-center border border-slate-300 rounded">
          <div className="sm:mx-auto sm:w-full sm:max-w-sm">
            <CustomHeading text="Log in to your account" />
            <p className="text-center break-words text-sm mt-2 decoration-slate-400	">
              Join us today and experience the full power of AI-driven bot
              creation for your business or for yourself. You decide!
            </p>
          </div>

          <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
            <form className="space-y-6" onSubmit={handleSubmitForm}>
              <CustomLabel
                label="Email Address"
                htmlFor="email"
                labelClassNames="text-start"
              />
              <CustomTextInput
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                required
                placeholder="name@emample.com"
                inputClassNames="!mt-1"
              />

              <div>
                <CustomLabel label="Password" htmlFor="password" />
                <CustomTextInput
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  placeholder="Enter your password"
                  inputClassNames="!mt-1"
                />
                <div className="text-sm mt-3 flex items-center justify-between">
                  <div className="remember">
                    {" "}
                    <div className="flex items-center gap-x-3">
                      <input
                        id="push-email"
                        name="push-notifications"
                        type="checkbox"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600 "
                      />
                      <label
                        htmlFor="push-email"
                        className="block text-sm font-medium leading-6 text-gray-900 whitespace-nowrap"
                      >
                        Remember me
                      </label>
                    </div>
                  </div>
                  <Link to="/forget-password">Forgot password?</Link>
                </div>
              </div>

              <div className="flex justify-center item-center">
                <CustomButton title=" Sign in" type="submit" />
              </div>
            </form>

            <Divider className="!my-4">or</Divider>
            <div className="!my-4 flex justify-center">
              <img src={social_icons} alt="social_icons" width={146} />
            </div>
            <div
              className="py-4 text-sm"
              style={{ backgroundColor: "#FFEBEB" }}
            >
              Don't have an account?{" "}
              <span>
                <Link
                  to="/signup"
                  className="cursor-pointer font-semibold leading-6 "
                >
                  Sign Up
                </Link>
              </span>
            </div>
          </div>
        </div>
      </div>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default Login;

import * as React from "react";
import Badge from "@mui/material/Badge";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
// Notification
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { removeItemFromLocalStorage } from "../../services/localStorage";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import user_logo from "../../assets/images/user_logo.png";
import profile_card_logo from "../../assets/images/profile_card_logo.png";
import rocket_logo from "../../assets/images/rocket_logo.png";
import logout_logo from "../../assets/images/logout_logo.png";
import str from "../../assets/images/str.png";
import "./index.css";
import FeedbackModal from "../../components/FeedbackModal";
import { useDispatch, useSelector } from "react-redux";
import { clearAllCookes } from "../../pages/Auth/store/authSlice";
import LoaderComponent from "../../components/LoaderComponent";
import { clearCustomizationState } from "../../redux/reducers/customization/customizationSlice";

function Navbar({ showHeaderRight = true }) {
  const navigate = useNavigate();
  const state = useSelector((state) => state?.auth);

  const dispatch = useDispatch();

  const [openFeedbackModal, setOpenFeedbackModal] = React.useState();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const [notEl, setNotEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const openNotification = Boolean(notEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (key) => {
    setAnchorEl(null);
  };

  const handleClickMenu = (e, key) => {
    e.preventDefault();
    if (key) {
      navigate(key);
      handleClose();
    } else {
      handleClose();
    }
  };

  const handleClickNotification = (event) => {
    setNotEl(event.currentTarget);
  };
  const handleCloseNotification = () => {
    setNotEl(null);
  };

  const handleLogout = async () => {
    setLoading(true);
    await dispatch(clearAllCookes());
    await dispatch(clearCustomizationState());
    removeItemFromLocalStorage("access_token");
    removeItemFromLocalStorage("refresh_token");
    removeItemFromLocalStorage("project_id");
    removeItemFromLocalStorage("bot_id");
    navigate(`/login`);
  };

  const handleOpenFeedbackModal = () => setOpenFeedbackModal(true);
  const handleFeedbackModalClose = () => setOpenFeedbackModal(false);

  return (
    <>
      <div className="w-[100%]">
        <div className="bg-white">
          <div className="flex-col flex items-center justify-center">
            <div
              className=" border-gray-200  sticky w-[100%] rounded-lg"
              style={{
                boxShadow:
                  "0px 0px .9310142993927002px 0px rgba(0, 0, 0, .17), 0px 0px 3.1270833015441895px 0px rgba(0, 0, 0, .08), 0px 7px 14px 0px rgba(0, 0, 0, .05)",
              }}
            >
              <div className="bg-white h-16 justify-between items-center rounded-lg inside-cont flex ">
                <div>
                  <img
                    src={logo}
                    className="block btn- h-8 w-auto cursor-pointer"
                    alt=""
                    onClick={() => navigate(`/`)}
                  />
                </div>
                <div className="md:space-x-6 justify-end items-center ml-auto flex space-x-3">
                  <Box
                    sx={{
                      display: `${showHeaderRight ? "flex" : "none"}`,
                      alignItems: "center",
                      textAlign: "center",
                    }}
                  >
                    {/* Notification Icon */}
                    <Tooltip title="Notifications">
                      <IconButton
                        onClick={handleClickNotification}
                        size="small"
                        sx={{ ml: 2 }}
                        aria-controls={open ? "account-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                        className="gradient-background"
                        style={{ color: "white", borderRadius: "20%" }}
                      >
                        <Badge color="error" badgeContent=" " variant="dot">
                          <NotificationsIcon size="small" />
                        </Badge>
                      </IconButton>
                    </Tooltip>

                    <div
                      className="nav-ancher"
                      onClick={handleOpenFeedbackModal}
                    >
                      <div>
                        Feedback
                        <img src={str} alt="" className="ml-2" />
                      </div>
                    </div>

                    {/* Account Settings Icons */}
                    <Tooltip title="Account settings">
                      <IconButton
                        onClick={handleClick}
                        size="small"
                        sx={{ ml: 2 }}
                        aria-controls={open ? "account-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                      >
                        <Avatar sx={{ width: 32, height: 32 }}>M</Avatar>
                      </IconButton>
                    </Tooltip>
                  </Box>

                  {/* Notification Menu */}
                  <Menu
                    anchorEl={notEl}
                    id="account-menu"
                    open={openNotification}
                    onClose={handleCloseNotification}
                    onClick={handleCloseNotification}
                    PaperProps={{
                      elevation: 0,
                      sx: {
                        overflow: "visible",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                        mt: 1.5,
                        "& .MuiAvatar-root": {
                          width: 32,
                          height: 32,
                          ml: -0.5,
                          mr: 1,
                        },
                        "&:before": {
                          content: '""',
                          display: "block",
                          position: "absolute",
                          top: 0,
                          right: 14,
                          width: 10,
                          height: 10,
                          bgcolor: "background.paper",
                          transform: "translateY(-50%) rotate(45deg)",
                          zIndex: 0,
                        },
                      },
                    }}
                    transformOrigin={{ horizontal: "right", vertical: "top" }}
                    anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                  >
                    <List
                      sx={{
                        width: "100%",
                        maxWidth: 360,
                        bgcolor: "background.paper",
                      }}
                    >
                      <ListItem alignItems="flex-start">
                        <ListItemText
                          primary="Brunch this weekend?"
                          secondary={
                            <React.Fragment>
                              <Typography
                                sx={{ display: "inline" }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                              >
                                Ali Connors
                              </Typography>
                              {
                                " — I'll be in your neighborhood doing errands this…"
                              }
                            </React.Fragment>
                          }
                        />
                      </ListItem>
                      <Divider component="li" />
                      <ListItem alignItems="flex-start">
                        <ListItemText
                          primary="Summer BBQ"
                          secondary={
                            <React.Fragment>
                              <Typography
                                sx={{ display: "inline" }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                              >
                                to Scott, Alex, Jennifer
                              </Typography>
                              {
                                " — Wish I could come, but I'm out of town this…"
                              }
                            </React.Fragment>
                          }
                        />
                      </ListItem>
                      <Divider component="li" />
                      <ListItem alignItems="flex-start">
                        <ListItemText
                          primary="Oui Oui"
                          secondary={
                            <React.Fragment>
                              <Typography
                                sx={{ display: "inline" }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                              >
                                Sandra Adams
                              </Typography>
                              {
                                " — Do you have Paris recommendations? Have you ever…"
                              }
                            </React.Fragment>
                          }
                        />
                      </ListItem>
                    </List>
                  </Menu>

                  {/* Account settings menu */}
                  <Menu
                    anchorEl={anchorEl}
                    id="account-menu"
                    open={open}
                    onClose={handleClose}
                    onClick={handleClose}
                    PaperProps={{
                      borderRadius: "10px",
                      elevation: 0,

                      sx: {
                        overflow: "visible",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                        mt: 1.5,
                        "& .MuiAvatar-root": {
                          width: 32,
                          height: 32,
                          ml: -0.5,
                          mr: 1,
                        },
                        "&:before": {},
                      },
                    }}
                    transformOrigin={{ horizontal: "right", vertical: "top" }}
                    anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                  >
                    <MenuItem onClick={(e) => handleClickMenu(e, "/profile")}>
                      <Avatar style={{ border: "3px solid #9264ef" }} />{" "}
                      {state?.user?.first_name ??
                        "" + state?.user?.last_name ??
                        ""}
                    </MenuItem>
                    <Divider />
                    {/* <MenuItem onClick={(e) => handleClickMenu(e, "/profile")}>
                      <ListItemIcon>
                        <img
                          src={user_logo}
                          alt="logo"
                          width={16}
                          height={16}
                        />
                      </ListItemIcon>
                      Admin
                    </MenuItem> */}
                    <MenuItem onClick={(e) => handleClickMenu(e, "/profile")}>
                      <ListItemIcon>
                        <img
                          src={profile_card_logo}
                          alt="logo"
                          width={16}
                          height={16}
                        />
                      </ListItemIcon>
                      Profile
                    </MenuItem>
                    <MenuItem onClick={(e) => handleClickMenu(e, "/settings")}>
                      <ListItemIcon>
                        <img
                          src={rocket_logo}
                          alt="logo"
                          width={16}
                          height={16}
                        />
                      </ListItemIcon>
                      Upgrade
                    </MenuItem>
                    <MenuItem onClick={handleLogout}>
                      <ListItemIcon>
                        <img
                          src={logout_logo}
                          alt="logo"
                          width={16}
                          height={16}
                        />
                      </ListItemIcon>
                      Logout
                    </MenuItem>
                  </Menu>

                  {/* FeedbackModal */}
                  {openFeedbackModal && (
                    <FeedbackModal
                      open={openFeedbackModal}
                      setOpen={setOpenFeedbackModal}
                      handleOpen={handleFeedbackModalClose}
                      handleClose={handleFeedbackModalClose}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <LoaderComponent loading={loading} />
    </>
  );
}

export default Navbar;

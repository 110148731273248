import { Backdrop, CircularProgress } from "@mui/material";
import React from "react";
import "./index.css";

const LoaderComponent = ({ loading, className }) => {
  return (
    <Backdrop
      sx={{
        color: "#fff",
        zIndex: (theme) => theme.zIndex.drawer + 1,
        opacity: 0.4,
      }}
      open={loading}
      className={`${className}`}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default LoaderComponent;

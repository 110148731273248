import * as React from "react";
import Slider from "@mui/material/Slider";

export default function TokenPurchaseSlider({
  handleChange = () => {},
  name = "",
  disabled = false,
  marks = [],
}) {
  return (
    <Slider
      aria-label="Restricted values"
      defaultValue={marks[0]?.value}
      step={null}
      valueLabelDisplay="auto"
      marks={marks}
      onChange={handleChange}
      min={Math.min(...marks.map((mark) => mark.value))}
      max={Math.max(...marks.map((mark) => mark.value))}
      name={name}
      disabled={disabled}
    />
  );
}

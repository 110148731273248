import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../../services/api";

const initialState = {
  customization: null,
};

export const getCustomization = createAsyncThunk(
  "customization/getCustomization",
  async ({ botId, projectId }) => {
    try {
      let response = await api.get(
        `customization/?bot_id=${botId}&project_id=${projectId}`
      );
      let data = response?.data;
      return data;
    } catch (error) {
      console.error("Error getting credits count", error);
    }
  }
);

export const updateCustomization = createAsyncThunk(
  "customization/updateCustomization",
  async () => {
    try {
      let response = await api.get(`/users/me`);
      return response?.data;
    } catch (error) {
      console.error("Error getting credits count", error);
    }
  }
);

export const customizationSlice = createSlice({
  name: "customization",
  initialState,
  reducers: {
    updateCustomizationReducer: (state, { payload }) => {
      state.customization = payload;
    },
    clearCustomizationState: (state) => {
      state.customization = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCustomization.fulfilled, (state, { payload }) => {
        state.customization = payload;
      })

      .addCase(updateCustomization.fulfilled, (state, { payload }) => {
        console.log("updateCustomization.fulfilled", payload);
        state.customization = payload;
      });
  },
});

// Action creators are generated for each case reducer function
export const { updateCustomizationReducer, clearCustomizationState } =
  customizationSlice.actions;
export const customizationSelector = (state) => state.customization;

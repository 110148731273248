import { WIDGET_URL } from "../constants/URLS";
import { countries } from "../constants/countries";

export const getFormValues = (event) => {
  const formData = new FormData(event.target);
  const values = Object.fromEntries(formData.entries());

  return values;
};

export const USDollar = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export const getDate = (date) => {
  const dateObj = new Date(date);
  const formattedDate = dateObj?.toLocaleString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: true,
  });

  return formattedDate;
  // return dateObj?.toLocaleString([], {
  //   weekday: "short",
  //   hour: "2-digit",
  //   minute: "2-digit",
  // });
};

// Function to get random integers within a range
function getRandomInt(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

// Function to get a random subset of objects from the array
function getRandomSubset(array, size) {
  const shuffled = array.slice(); // Create a shallow copy of the array
  let i = array.length;

  // While there remain elements to shuffle...
  while (i--) {
    const randomIndex = getRandomInt(0, i);
    // Swap element at randomIndex with the current element
    [shuffled[i], shuffled[randomIndex]] = [shuffled[randomIndex], shuffled[i]];
  }

  return shuffled.slice(0, size); // Return the first 'size' elements
}

export const getRandomObjects = (array, quantity) => {
  const randomSubset = getRandomSubset(array, quantity);
  return randomSubset;
};

export const createSlug = (fileName) => {
  let cleanedName = fileName.replace(/[^\w\s]/g, "");
  let slug = cleanedName.replace(/\s+/g, "_").toLowerCase();

  return slug;
};

export const getCountryByCode = (code) => {
  let country = countries?.find((item) => item?.code === code);
  return country?.name;
};

export const generateCodeSnippet = (widget) => {
  const scriptConfig = {
    isOpen: false,
    position: "right",
    project_id: widget.project_id,
    bot_id: widget.bot_id,
    user_id: widget.user_id,
  };

  return (
    "<script>\n" +
    "(function(d,t) {\n" +
    "const b='" +
    WIDGET_URL +
    "';\n" +
    "const g=d.createElement(t),s=d.getElementsByTagName(t)[0];\n" +
    "g.src=b;\n" +
    "g.defer = true;\n" +
    "g.async = true;\n" +
    "s.parentNode.insertBefore(g,s);\n" +
    "g.onload=function(){\n" +
    "window.$bn_widget = " +
    JSON.stringify(scriptConfig) +
    ";\n" +
    "window.bn_initChat();\n" +
    "};\n" +
    "})(document,'script')\n" +
    "</script>\n"
  );
};

export function generateRandomString(length) {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    result += characters.charAt(randomIndex);
  }

  return result;
}

export const getFullLocation = (visitor_details) => {
  let location = "";
  if (visitor_details?.city) location += `${visitor_details?.city}`;
  if (visitor_details?.stateProv) location += `, ${visitor_details?.stateProv}`;
  if (visitor_details?.countryName)
    location += `, ${visitor_details?.countryName}`;

  return location;
};

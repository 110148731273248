import React from "react";
import Avatar from "@mui/material/Avatar";
import user_avatar from "../../../../assets/images/user_avatar.png";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import "./index.css";
import { useNavigate } from "react-router-dom";

const WidgetHeader = ({ widgetAppereance, fromConv }) => {
  const navigate = useNavigate();

  return (
    <>
      <div
        className={`flex justify-between p-4 items-center  rounded-t-lg ${
          !widgetAppereance?.widget_header_color && "gradient-background"
        } `}
        style={{
          borderBottom: "1px solid rgba(0, 0, 0, 0.05)",
          boxShadow: "rgba(0, 0, 0, 0.05) 0px 0px 25px",
          background:
            widgetAppereance?.widget_header_color &&
            widgetAppereance?.widget_header_color,
        }}
      >
        <div>
          {fromConv ? (
            <ArrowBackIosIcon
              className="cursor-pointer ml-3"
              onClick={() => navigate(`/conversations`)}
            />
          ) : (
            <Avatar
              className="bg-white p-0.5"
              src={
                widgetAppereance?.client_logo_url &&
                widgetAppereance?.client_logo_url !== ""
                  ? widgetAppereance?.client_logo_url
                  : user_avatar
              }
            />
          )}
        </div>
        <div>
          <p className="text-md font-semibold text-white">Chat with us</p>
        </div>
        <div></div>
      </div>
    </>
  );
};

export default WidgetHeader;

import React, { useEffect, useState } from "react";
import { getFormValues } from "../../utils/helper";
import { api } from "../../services/api";
import { useSnackbar } from "notistack";
import { updateBot } from "../../pages/Auth/SignupSteps/APIs/botApi";
import CustomHeading from "../CustomHeading";
import CustomLabel from "../CustomLabel";
import LoaderComponent from "../LoaderComponent";
import CustomButton from "../CustomButton";
import { useDispatch, useSelector } from "react-redux";
import { getCustomization } from "../../redux/reducers/customization/customizationSlice";

const WidgetCustomizer = ({ widgetAppereance, setWidgetAppearance }) => {
  const dispatch = useDispatch();
  const [arrayOfStrings, setArrOfString] = useState([]);
  const [loading, setLoading] = useState(false);

  const [bot, setBot] = useState();
  const state = useSelector((state) => state?.auth);

  const { enqueueSnackbar } = useSnackbar();

  const updateWidget = async (formValues) => {
    try {
      const apiResponse = await api.put(
        `customization/${formValues?.id}`,
        formValues
      );
      // const uploadPictureRes = await uploadLogo(formValues);
      let bot_name_update = {
        bot_name: formValues?.bot_name,
        id: formValues?.bot_id,
      };
      const updateBotName = await updateBot(bot_name_update);
      await dispatch(
        getCustomization({
          botId: state?.bot_id,
          projectId: state?.project_id,
        })
      );
      enqueueSnackbar("Successfully updated widget!", { variant: "success" });
    } catch (error) {
      console.log("Error", error.details);
      enqueueSnackbar("Something went wrong. Please Try again!", {
        variant: "error",
      });
    }
  };

  const createWidget = async (formValues) => {
    try {
      const apiResponse = await api.post(`customization/`, formValues);
      const uploadPictureRes = await uploadLogo(formValues);

      let bot_name_update = {
        bot_name: formValues?.bot_name,
        id: formValues?.bot_id,
      };
      const updateBotName = await updateBot(bot_name_update);

      enqueueSnackbar("Successfully updated widget!", { variant: "success" });
    } catch (error) {
      console.log("Error", error.details);
      enqueueSnackbar("Something went wrong. Please Try again!", {
        variant: "error",
      });
    }
  };

  const uploadLogo = (formValues) => {
    let values = {
      bot_id: formValues?.bot_id,
      project_id: formValues?.project_id,
      profile_pic: formValues?.profile_pic,
    };

    try {
      const response = api.post(
        `customization/upload_logo?project_id=${values?.project_id}&bot_id=${values?.bot_id}`,
        { client_logo: formValues?.profile_pic },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      return response?.data;
    } catch (error) {
      console.log(error);
    }
  };

  const onFormSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();
    const values = await getFormValues(event);

    console.log(values?.widget_header_color);
    setWidgetAppearance({
      ...widgetAppereance,
      ...values,
      initial_suggested_prompt: widgetAppereance?.initial_suggested_prompt,
      widget_header_color:
        values?.widget_header_color === "#000000"
          ? null
          : values?.widget_header_color,

      widget_icon_color:
        values?.widget_icon_color === "#000000"
          ? null
          : values?.widget_icon_color,
    });
    let updatedValues = {
      ...widgetAppereance,
      initial_suggested_prompt: widgetAppereance?.initial_suggested_prompt,
      ...values,
    };

    let formValues = {
      ...updatedValues,
      initial_suggested_prompt: widgetAppereance?.initial_suggested_prompt,
      bot_id: state?.bot_id,
      project_id: state?.project_id,
      widget_header_color:
        values?.widget_header_color === "#000000"
          ? null
          : values?.widget_header_color,

      widget_icon_color:
        values?.widget_icon_color === "#000000"
          ? null
          : values?.widget_icon_color,
    };

    if (formValues?.id) {
      // console.log("formValues Update", formValues);
      await updateWidget(formValues);
      setLoading(false);
    } else {
      console.log("formValues Create", formValues);
      await createWidget(formValues);
      setLoading(false);
    }
  };
  const processInput = (e) => {
    let userInput = e.target.value;
    if (userInput === "") {
      let newArray = [];
      setWidgetAppearance({
        ...widgetAppereance,
        initial_suggested_prompt: newArray,
      });
      setArrOfString(newArray);
    }
    if (userInput?.trim() !== "") {
      const lines = userInput.split("\n");
      setArrOfString(lines);

      setWidgetAppearance({
        ...widgetAppereance,
        initial_suggested_prompt: lines,
      });
    } else {
      if (e.keyCode === 8) {
        setArrOfString([]);
        setWidgetAppearance({
          ...widgetAppereance,
          initial_suggested_prompt: [],
        });
      }
    }
  };
  useEffect(() => {
    const get = async () => {
      let response = state?.allBots?.filter(
        (item) => item?.id === state?.bot_id
      );

      setBot(response[0]);
    };
    if ((state?.bot_id, state?.allBots)) get();
  }, [state?.bot_id, state?.allBots]);

  return (
    <div className=" mt-3 h-[100%] ">
      <form onSubmit={onFormSubmit}>
        <div className="space-y-12 ">
          <div className="!mt-5 ">
            <CustomHeading text="Chat interface" />
            <p className="mt-1 text-sm leading-6 text-gray-600">
              Applies when embedded on website
            </p>

            <div className="mt-5 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="col-span-full">
                <label
                  htmlFor="bot_name"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Bot Name
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    placeholder="Bot Name"
                    id="bot_name"
                    name="bot_name"
                    className="block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    defaultValue={bot?.bot_name}
                  />
                </div>
              </div>

              <div className="col-span-full">
                <label
                  htmlFor="initial_message"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Inital Messages
                </label>
                <div className="mt-2">
                  <textarea
                    placeholder="Write inital message"
                    id="initial_message"
                    name="initial_message"
                    rows={2}
                    value={widgetAppereance?.initial_message}
                    className="block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    onChange={(e) =>
                      setWidgetAppearance({
                        ...widgetAppereance,
                        initial_message: e.target.value,
                      })
                    }
                  />
                </div>
              </div>

              {/* <div className="col-span-full">
                <label
                  htmlFor="initial_suggested_prompt"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Suggested Message
                </label>
                <div className="mt-2">
                  <textarea
                    placeholder="Write suggested message"
                    id="initial_suggested_prompt"
                    name="initial_suggested_prompt"
                    rows={2}
                    className="block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    defaultValue={widgetAppereance?.initial_suggested_prompt?.join(
                      "\n"
                    )}
                    onChange={processInput}
                  />
                </div>
              </div> */}

              <div className="col-span-full">
                <label
                  htmlFor="widget_position"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Widget Placement Position
                </label>
                <div className="mt-2">
                  <select
                    placeholder="Write suggested message"
                    id="widget_position"
                    name="widget_position"
                    value={widgetAppereance?.widget_position}
                    onChange={(e) =>
                      setWidgetAppearance({
                        ...widgetAppereance,
                        widget_position: e.target.value,
                      })
                    }
                    className="block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  >
                    <option value="left">Left</option>
                    <option value="right">Right</option>
                  </select>
                </div>
              </div>
              <div className="col-span-full">
                <CustomLabel
                  htmlFor="widget_header_color"
                  label="Widget Header Color"
                />

                <div className="mt-2">
                  <input
                    id="widget_header_color"
                    name="widget_header_color"
                    type="color"
                    defaultValue={widgetAppereance?.widget_header_color}
                    value={widgetAppereance?.widget_header_color}
                    onChange={(e) =>
                      setWidgetAppearance({
                        ...widgetAppereance,
                        widget_header_color: e.target.value,
                      })
                    }
                  />
                </div>
              </div>

              <div className="col-span-full">
                <CustomLabel
                  htmlFor="widget_icon_color"
                  label="Widget Icon Color"
                />

                <div className="mt-2">
                  <input
                    id="widget_icon_color"
                    name="widget_icon_color"
                    type="color"
                    defaultValue={widgetAppereance?.widget_icon_color}
                    value={widgetAppereance?.widget_icon_color}
                    onChange={(e) =>
                      setWidgetAppearance({
                        ...widgetAppereance,
                        widget_icon_color: e.target.value,
                      })
                    }
                  />
                </div>
              </div>

              {/* <div className="col-span-full">
                <label
                  htmlFor="theme"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Theme
                </label>
                <div className="mt-2">
                  <select
                    placeholder="Write suggested message"
                    id="theme"
                    name="theme"
                    className="block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    defaultValue={""}
                  >
                    <option>Light</option>
                    <option>Dark</option>
                  </select>
                </div>
              </div> */}

              {/* <div className="col-span-full">
                <label
                  htmlFor="profile_pic"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Update chatbot profile picture
                </label>
                <div className="mt-2">
                  <input
                    type="file"
                    id="profile_pic"
                    name="profile_pic"
                    rows={2}
                    className="block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    defaultValue={""}
                  />
                </div>
              </div> */}
            </div>
          </div>
        </div>

        <div className="mt-5 flex items-center justify-start gap-x-6">
          <CustomButton title="Save" type="submit" />
          <button
            type="button"
            className="text-sm font-semibold leading-6 text-gray-900"
          >
            Cancel
          </button>
        </div>
      </form>

      <LoaderComponent loading={loading} />
    </div>
  );
};

export default WidgetCustomizer;

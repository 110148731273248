import React, { useState } from "react";
import "./index.css";
import { AudioRecorder, useAudioRecorder } from "react-audio-voice-recorder";
import { CHATBOT_MODELLING_API_URL } from "../../../../constants/URLS";
import { generateRandomString } from "../../../../utils/helper";
import send_icon from "../../../../assets/images/send_icon.png";
import { useSelector } from "react-redux";
const SendBox = ({
  sendMessage,
  msgProcessing,
  conversationType,
  trainingMessages,
  scrollToBottom,
  setEnd,
  appendString,
  user,
  sessionId,
  messages,
  setMessages,
}) => {
  const state = useSelector((state) => state?.auth);
  const recorderControls = useAudioRecorder(
    {
      noiseSuppression: true,
      echoCancellation: true,
    },
    (err) => console.table(err) // onNotAllowedOrFound
  );

  const postMessage = async (file) => {
    try {
      setEnd(true);
      let project_id = state?.project_id;
      let bot_id = state?.bot_id;

      let session_id = sessionId;
      let user_id = user?.id;

      let obj = {
        bot_id: bot_id,
        session_id: session_id,
        user_id: user_id,
        project_id: project_id,
      };

      let url = `${CHATBOT_MODELLING_API_URL}query?user_id=${obj?.user_id}&project_id=${obj?.project_id}&bot_id=${obj?.bot_id}&session_id=${obj?.session_id}`;
      let formData = new FormData();
      let audioFile = new File([file], `${generateRandomString(5)}.mp3`);

      formData.append("voice_note", audioFile);

      var response = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "multipart/form-data",
        },
        body: formData,
      });
      var reader = response.body.getReader();
      var decoder = new TextDecoder("utf-8");
      async function processResult() {
        while (true) {
          const { done, value } = await reader.read();
          if (done) break;

          let token = decoder.decode(value);

          if (
            token.endsWith(".") ||
            token.endsWith("!") ||
            token.endsWith("?")
          ) {
            appendString(token);
          } else {
            appendString(token);
          }
        }
      }

      await processResult();
      setEnd(false);
    } catch (error) {
      console.log("error", error);
    } finally {
    }
  };

  const addAudioElement = async (blob) => {
    const url = URL.createObjectURL(blob);

    const newObject = {
      id: messages.length + 1,
      voice_path: url,
      timestamp: new Date(),
      isVisitor: true,
    };
    setMessages([...messages, newObject]);
    await postMessage(blob);
  };
  const [checkText, setCheckText] = useState(false);
  const [text, setText] = useState("");

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      addMessage();
    }
  };

  const addMessage = async () => {
    if (text.trim() !== "") {
      setCheckText(true);
      if (conversationType === "train") {
        await trainingMessages(text);
        scrollToBottom();
        setText("");
        setCheckText(false);

        return;
      } else if (conversationType === "new-conversation") {
        await sendMessage(text);
        setText("");
        setCheckText(false);

        return;
      }
    }
  };

  return (
    <div className="center-div">
      <div
        className="flex justify-between "
        style={{
          margin: "0 0 1rem",
          padding: " 0.8rem",
          width: "90%",
          minHeight: "100px",
          // height: "80px",
          background: "#F2F2F4",
          borderBottom: "1px solid rgba(0, 0, 0, 0.05)",
          boxShadow: "rgba(0, 0, 0, 0.05) 0px 0px 25px",
          borderRadius: "5px",
        }}
      >
        <div className="w-[100%] text-sm">
          <textarea
            disabled={msgProcessing}
            className="visitor-text-area bg-white p-4"
            placeholder="Write your message.."
            rows={Math.min(text.split("\n").length, 3)} // Set rows dynamically with a maximum limit
            value={checkText ? "" : text}
            onChange={(e) => setText(e.target.value)}
            onKeyDown={handleKeyPress}
          ></textarea>
        </div>
        <div className="p-2 flex justify-center items-center">
          {conversationType === "new-conversation" && (
            <div>
              <AudioRecorder
                onRecordingComplete={(blob) => addAudioElement(blob)}
                recorderControls={recorderControls}
                showVisualizer={true}
              />
            </div>
          )}
          <div className="ml-3">
            <img src={send_icon} alt="send_icon" onClick={addMessage} />
            {/* <SendIcon onClick={addMessage} /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SendBox;

import AppLayout from "../Layout";
import Signup from "../pages/Auth/Signup";
import SignupSteps from "../pages/Auth/SignupSteps";
import Login from "../pages/Auth/Login";
import WidgetSettings from "../pages/Settings/WidgetSettings";
import Playground from "../pages/Playground";
import BotSettings from "../pages/Settings/BotSettings";
import Projects from "../pages/Projects";
import Knowledgebase from "../pages/Knowledgebase";
import Analytics from "../pages/Analytics";
import Profile from "../pages/Profile";
import ErrorPage from "../pages/Payment/ErrorPage";
import SuccessPage from "../pages/Payment/SuccessPage";
import ConversationList from "../pages/ConversationList";
import Chat from "../pages/Chat";
import EnforcementLearning from "../pages/EnforcementLearning";
import ForgetPassword from "../pages/Auth/ForgetPassword";
import ResetPassword from "../pages/Auth/ResetPasword";
import Bots from "../pages/Bots";

export const routes = [
  { key: "signup", path: "/signup", element: <Signup /> },
  { key: "login", path: "/login", element: <Login /> },
  {
    key: "forget-password",
    path: "/forget-password",
    element: <ForgetPassword />,
  },
  {
    key: "reset-password",
    path: "/reset-password",
    element: <ResetPassword />,
  },

  {
    key: "signup-steps",
    path: "/signup-steps",
    element: <SignupSteps />,
    private: true,
  },
  { key: "layout", path: "/*", element: <AppLayout />, private: true },
  { key: "cancel", path: "/billing/cancel", element: <ErrorPage /> },
  { key: "success", path: "/billing/success", element: <SuccessPage /> },
];

export const layoutRoutes = [
  { key: "analytics", path: "/", element: <Analytics /> },
  { key: "analytics", path: "/analytics", element: <Analytics /> },
  { key: "profile", path: "/profile", element: <Profile /> },

  { key: "settings", path: "/widget-settings", element: <WidgetSettings /> },
  {
    key: "bot-settings",
    path: "/bot-settings",
    element: <BotSettings />,
  },
  { key: "playground", path: "/playground", element: <Playground /> },
  { key: "learning", path: "/learning", element: <EnforcementLearning /> },

  { key: "projects", path: "/projects", element: <Projects /> },
  { key: "bots", path: "/bots", element: <Bots /> },
  { key: "add-new-bot", path: "/create-bot", element: <Bots /> },

  {
    key: "conversations",
    path: "/conversations",
    element: <ConversationList />,
  },
  {
    key: "chat",
    path: "/chat/:sessionId",
    element: <Chat />,
  },

  { key: "knowledgebase", path: "/knowledgebase", element: <Knowledgebase /> },
];

import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { api } from "../../services/api";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import CustomHeading from "../../components/CustomHeading";
import { styled } from "@mui/material/styles";

import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { useSelector } from "react-redux";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 17,

  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: "#000C60",
  },
}));

export default function Analytics() {
  const [aggregationResults, setAggregationResults] = useState();
  const state = useSelector((state) => state?.auth);

  const getAnalytics = async () => {
    try {
      let response = await api.get("dashboard/get_credit_counts");
      const botAnalytics = response?.data?.filter(
        (item) => item?.bot_id === state?.bot_id
      );
      setAggregationResults(botAnalytics[0]?.aggregation_result);
    } catch (err) {
      console.log("Error", err);
    }
  };

  useEffect(() => {
    if (state?.bot_id) {
      getAnalytics();
    }
  }, [state?.bot_id]);

  const data = {
    labels: aggregationResults?.map((label) => label.date),
    datasets: [
      {
        label: "Tokens used",
        data: aggregationResults?.map((label) => label.total_tokens),
        borderColor: "#1A76D3",
        backgroundColor: "#1A76D3",
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
        position: "top",
      },
      title: {
        display: false,
        text: "Total tokens used",
      },
    },
  };
  return (
    <div className="">
      <CustomHeading text="Total tokens used" />
      <BorderLinearProgress
        variant="determinate"
        value={50}
        style={{ margin: "1rem 0", maxWidth: "600px  " }}
      />
      <div className="line_chart max-h-[400px] flex mt-5 ">
        <Line options={options} data={data} className="!w-[100%] !h-auto" />
      </div>
    </div>
  );
}

import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../../assets/images/logo.png";
import CustomButton from "../../../components/CustomButton";

const ErrorPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Redirect after 10 seconds
    const timer = setTimeout(() => {
      navigate(`/`);
    }, 10000); // 10 seconds in milliseconds

    // Cleanup the timeout if the component is unmounted
    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <div>
      <div className="bg-gray-100 h-screen">
        <div className="bg-white p-6  md:mx-auto flex h-[100%] flex-col justify-center items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width="100"
            height="100"
            viewBox="0 0 48 48"
          >
            <path
              fill="#f44336"
              d="M44,24c0,11.045-8.955,20-20,20S4,35.045,4,24S12.955,4,24,4S44,12.955,44,24z"
            ></path>
            <path
              fill="#fff"
              d="M29.656,15.516l2.828,2.828l-14.14,14.14l-2.828-2.828L29.656,15.516z"
            ></path>
            <path
              fill="#fff"
              d="M32.484,29.656l-2.828,2.828l-14.14-14.14l2.828-2.828L32.484,29.656z"
            ></path>
          </svg>
          <br></br>
          <div className="text-center">
            <div className="flex justify-center items-center mb-5">
              <img
                src={logo}
                className="block btn- h-10 w-auto cursor-pointer"
                alt=""
                onClick={() => navigate(`/`)}
              />
            </div>
            <h3 className="md:text-2xl text-base text-gray-900 font-semibold text-center">
              Payment not done!
            </h3>
            <p className="text-gray-600 my-2">
              Unfortunately, Something went wring.
            </p>
            <p> Please try again later! </p>
            <CustomButton
              className="!mt-4"
              onClick={() => navigate(`/analytics`)}
              title="Back to home"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;

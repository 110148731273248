import React, { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import CustomHeading from "../CustomHeading";
import CustomButton from "../CustomButton";
import CloseIcon from "@mui/icons-material/Close";
import Backdrop from "@mui/material/Backdrop";
import TokenPurchaseSlider from "../TokenPurchaseSlider";
import { subscriptions_data } from "../../data/subscriptions_plans";
import { useSelector } from "react-redux";
import { STRIPE_PUBLISHABLE_KEY } from "../../utils/constants";
import { loadStripe } from "@stripe/stripe-js";
import { api } from "../../services/api";
import LoaderComponent from "../LoaderComponent";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  outline: "none",
  p: 3,
  borderRadius: "5px",
};

const CreditsModal = (props) => {
  const state = useSelector((state) => state.auth);
  const { open, handleClose } = props;

  const [selectedSubscription, setSelectedSubscription] = useState(
    subscriptions_data[0]
  );
  const [loading, setLoading] = useState(false);

  const makePayment = async () => {
    setLoading(true);
    const postData = {
      product_id: selectedSubscription?.stripe_product_id,
      purchase_token: selectedSubscription?.no_of_allowed_tokens,
      subscription_plan_id_local: selectedSubscription?.id,
    };
    const stripePromise = await loadStripe(STRIPE_PUBLISHABLE_KEY);
    let res = await api.post(`/billing/create-checkout-session`, postData);
    setLoading(false);
    return stripePromise.redirectToCheckout({
      sessionId: res?.data?.sessionId,
    });
  };

  const handleChangeSubscription = (event, value) => {
    const sub = subscriptions_data.find(
      (subscription) => subscription.no_of_allowed_tokens === value
    );
    if (sub) {
      setSelectedSubscription(sub);
    }
  };

  const marks = subscriptions_data.map((subscription) => ({
    value: subscription?.no_of_allowed_tokens,
    label: subscription.plan_name,
  }));

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <Box sx={style}>
          <div className="flex justify-between items-center">
            <CustomHeading text="Total Credits" />
            <CloseIcon className="cursor-pointer" onClick={handleClose} />
          </div>
          <br />
          <h2>
            Your remaing credits:{" "}
            <strong>{state?.remaining_tokens ?? "0"}</strong>
          </h2>
          <div className="w-[100%] mt-4 items-center justify-center flex">
            <Box sx={{ width: 480 }}>
              <TokenPurchaseSlider
                handleChange={handleChangeSubscription}
                marks={marks}
              />
            </Box>
          </div>

          <div className="text-end mt-5">
            Total tokens: {selectedSubscription?.no_of_allowed_tokens}{" "}
            <strong className="mr-3">
              {selectedSubscription?.currency +
                " " +
                selectedSubscription?.plan_price}
            </strong>{" "}
            <CustomButton title="Proceed" onClick={makePayment} />
          </div>
          <LoaderComponent loading={loading} className="rounded_5" />
        </Box>
      </Fade>
    </Modal>
  );
};

export default CreditsModal;

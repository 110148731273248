import * as React from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { getFullLocation } from "../../utils/helper";
import { conv_api } from "../../services/conv_api";
import CustomHeading from "../../components/CustomHeading";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useNavigate } from "react-router-dom";
import LoaderComponent from "../../components/LoaderComponent";
import { useSelector } from "react-redux";

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();

  const openConversation = async (session, row) => {
    navigate(`/chat/${session?.session_id}`);
  };
  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {(row?.visitor_details?.name ||
            row?.visitor_details?.visitor_default) ??
            "-"}
        </TableCell>
        <TableCell> {row?.visitor_details?.ipAddress ?? "-"}</TableCell>
        <TableCell>{getFullLocation(row?.visitor_details) ?? "-"}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell className="!font-bold">Session Id</TableCell>
                    <TableCell className="!font-bold">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row?.sessions?.map((session) => (
                    <TableRow key={session.id}>
                      <TableCell component="th" scope="row">
                        {session?.session_id}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        style={{ fontSize: 12, cursor: "pointer" }}
                        onClick={() => openConversation(session, row)}
                      >
                        <VisibilityIcon style={{ fontSize: 14 }} /> View
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function ConversationList() {
  const [conversations, setConversations] = React.useState([]);
  const state = useSelector((state) => state?.auth);
  const [loading, setLoading] = React.useState(false);

  const getConversations = async () => {
    setLoading(true);
    let user_id = state?.user?.id;
    let project_id = state?.project_id;
    let bot_id = state?.bot_id;
    try {
      let response = await conv_api.post(
        `list_conversations?user_id=${user_id}&project_id=${project_id}&bot_id=${bot_id}`
      );
      const dataWithTotalSessions = response?.data?.map((conv) => ({
        ...conv,
        total_sessions: conv.sessions?.length || 0,
      }));
      const filteredConversations = dataWithTotalSessions?.filter(
        (conv) =>
          conv.visitor_details && Object.keys(conv.visitor_details).length > 0
      );

      setConversations(filteredConversations);
    } catch (error) {
      // console.log("Error", error);
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    getConversations();
  }, [state?.project_id, state?.bot_id]);

  return (
    <>
      <CustomHeading text="Conversations" />

      <br />
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead style={{ background: "var(--gradient-color)" }}>
            <TableRow>
              <TableCell style={{ width: "0" }} />
              <TableCell className="!text-white">Visitor Name</TableCell>
              <TableCell className="!text-white">Ip Address</TableCell>
              <TableCell className="!text-white">Location</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {conversations?.map((row) => (
              <Row key={row.id} row={row} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <LoaderComponent loading={loading} />
    </>
  );
}

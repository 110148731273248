export const subscriptions_data = [
  {
    id: "66f2eb0adde2bea476c6cbd2",
    plan_name: "Basic Plan",
    plan_price: "29.99",
    currency: "$",
    no_of_allowed_characters: 500000,
    no_of_allowed_document_upload: 10,
    no_of_allowed_bot_creation: 3,
    no_of_allowed_project_creation: 1,
    stripe_product_id: "price_1OUSeIGKQA4JEOXgN6lQWkUa",
    no_of_allowed_tokens: 10000,
  },

  {
    id: "66f2eb0adde2bea476c6cbd3",
    plan_name: "Premium Plan",
    plan_price: "59.99",
    currency: "$",
    no_of_allowed_characters: 800000,
    no_of_allowed_document_upload: 15,
    no_of_allowed_bot_creation: 6,
    no_of_allowed_project_creation: 2,
    stripe_product_id: "price_1OUSkLGKQA4JEOXgGDB1yuL4",
    no_of_allowed_tokens: 22000,
  },
];

import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import { conv_api } from "../../../../services/conv_api";
import { api } from "../../../../services/api";
import { useSnackbar } from "notistack";
import LinearProgressWithLabel from "../../../../components/LinearProgressWithLabel";
import { createNewConversation } from "../../../../common/APIs";
import { Alert, Divider } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import CustomHeading from "../../../../components/CustomHeading";
import { styled } from "@mui/material/styles";
import link from "../../../../assets/images/link.png";
import "./index.css";
import FileUpload from "../../../../components/FileUpload";
import CustomTextInput from "../../../../components/CustomTextInput";
import { useDispatch, useSelector } from "react-redux";
import { getCreditsCount } from "../../../Auth/store/authSlice";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};
const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
export default function Uploader(props) {
  const state = useSelector((state) => state?.auth);

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [progress, setProgress] = React.useState(0);
  const [docType, setDocType] = React.useState("");

  const { open, setApiComplete, handleClose, totalDocs } = props;
  const [selectedFile, setSelectedFile] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const [docVerified, setDocVerified] = React.useState(false);
  const [docVerificationText, setDocVerificationText] = React.useState("");
  const [fileName, setFileName] = React.useState("");
  const [scrapFields, setScrapFields] = React.useState([{ id: 1 }]);
  const [urlsDisabled, setUrlsDisabled] = React.useState(false);
  const [fileDisabled, setFileDisabled] = React.useState(false);

  // const [scrapFields, setScrapFields] = React.useState([{ id: 1 }]);

  const handleFileChange = (event) => {
    setDocVerified(false);
    const file = event.target.files[0];

    if (file) {
      const fileType = file.type;

      // Check if the file type is either TXT or PDF
      if (fileType === "text/plain" || fileType === "application/pdf") {
        setSelectedFile(file);
      } else {
        alert("Please upload only TXT or PDF files.");
        // Clear the input field
        event.target.value = null;
      }
    }
  };
  const trainApi = async (withFile = true, check_scrap_urls) => {
    setLoading(true);
    try {
      const formData = new FormData();
      let web_scrape_urls =
        scrapFields
          ?.map((item) => {
            if (item?.value !== undefined) {
              return `&web_scrape_urls=${item.value}`;
            }
          })
          .join("") || "";

      if (withFile) {
        formData.append("file_one", selectedFile);
      } else {
        formData.append("file_one", "");
      }
      let project_id = state?.project_id;
      let bot_id = state?.bot_id;

      let user_id = state?.user?.id;

      // Simulate progress with a timer
      const simulateProgress = () => {
        let currentProgress = 0;
        const interval = setInterval(() => {
          currentProgress += 15;
          if (currentProgress <= 100) {
            updateProgress(currentProgress);
          } else {
            clearInterval(interval);
          }
        }, 500); // Adjust the interval as needed
      };

      simulateProgress();

      let response = await conv_api.post(
        `train_model?user_id=${user_id}&project_id=${project_id}&bot_id=${bot_id}${web_scrape_urls}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      let obj = {
        user_id: state?.user?.id,
        project_id: project_id,
        bot_id: bot_id,
      };

      await createNewConversation(obj);
      if (response?.data) {
        setProgress(100);
        enqueueSnackbar(response?.data, { variant: "success" });
        closeModal();
      }
    } catch (error) {
      enqueueSnackbar("Something went wrong. Please try again!", {
        variant: "error",
      });
    } finally {
      setLoading(false);
      setProgress(0);
      setApiComplete(true);
      dispatch(getCreditsCount());
    }
  };

  const docVerification = async () => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("file_one", selectedFile); // Adjust the key and filename as needed

      const simulateProgress = () => {
        let currentProgress = 0;
        const interval = setInterval(() => {
          currentProgress += 15;
          if (currentProgress <= 100) {
            updateProgress(currentProgress);
          } else {
            clearInterval(interval);
          }
        }, 500); // Adjust the interval as needed
      };

      simulateProgress();

      let response = await conv_api.post(`doc_length_assessment`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response?.data) {
        setProgress(100);
        setDocVerificationText(response?.data?.desc);
        setDocType(response?.data?.type);
      }

      setDocVerified(true);
      setFileName(selectedFile?.name);
    } catch (error) {
      enqueueSnackbar("Something went wrong. Please try again!", {
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const updateProgress = (progress) => {
    setProgress(progress);
  };

  const handleSave = async () => {
    let scrap_urls = scrapFields?.map((item) => item.value);
    let filter_urls = scrap_urls.some(
      (item) => item === undefined || item === ""
    );
    let check_file = selectedFile === null || selectedFile === undefined;
    let check_scrap_urls = filter_urls || scrap_urls?.length === 0;

    if (state?.user?.no_of_allowed_document_upload <= totalDocs) {
      enqueueSnackbar(`You cannot upload more than ${totalDocs} documents!`, {
        variant: "error",
      });
    } else if (!check_scrap_urls && !check_file) {
      enqueueSnackbar(
        "You are not allowed to upload document and web urls together!",
        { variant: "error" }
      );
    } else {
      if (docVerified) {
        trainApi();
      } else {
        if (!check_scrap_urls) {
          trainApi(false, check_scrap_urls);
          return;
        }
        docVerification();
      }
    }
  };

  const closeModal = () => {
    setLoading(false);
    setDocVerified(false);
    setSelectedFile();
    setScrapFields([{ id: 1 }]);
    handleClose();
    setUrlsDisabled(false);
    setFileDisabled(false);
  };

  const handleDownload = () => {
    if (selectedFile) {
      const url = URL.createObjectURL(selectedFile);
      const link = document.createElement("a");
      link.href = url;
      link.download = selectedFile.name;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  let severity = "";

  switch (docType) {
    case "Very Low":
    case "Low":
      severity = "error";
      break;
    case "Moderate":
      severity = "info";
      break;
    case "Good":
    case "Very Good":
      severity = "success";
      break;
    default:
      severity = "info";
  }

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    setSelectedFile(file);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const addField = async () => {
    setScrapFields((prevFields) => [
      ...prevFields,
      { id: prevFields.length + 1 },
    ]);
  };

  const removeField = async (id) => {
    setScrapFields((prevFields) =>
      prevFields.filter((field) => field.id !== id)
    );
  };

  const onChangeFields = async (e) => {
    const { name, value } = e.target;
    setScrapFields((prev) => {
      return prev.map((field) => {
        if (field.id === parseInt(name)) {
          return { ...field, value: value };
        }
        return field;
      });
    });
  };

  const onFocus = async (select) => {};

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={closeModal}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            {loading ? (
              <div>
                Training your model...{" "}
                <LinearProgressWithLabel value={progress} />
              </div>
            ) : (
              <div className="col-span-full ">
                <CustomHeading text="Update your Chatbot" />

                <div
                  className="mt-5"
                  // onDrop={handleDrop}
                  // onDragOver={handleDragOver}
                >
                  <FileUpload
                    selectedFile={selectedFile}
                    setSelectedFile={setSelectedFile}
                    disabled={fileDisabled}
                    onFocus={() => onFocus("fileInput")}
                    setUrlsDisabled={setUrlsDisabled}
                    scrapFields={scrapFields}
                    setFileDisabled={setFileDisabled}
                  />
                  {!docVerified && (
                    <>
                      <Divider className="!my-5 text-gray-500">or</Divider>
                      {scrapFields?.map((item, index) => (
                        <div
                          className="flex items-center justify-center mt-2 "
                          key={item?.id}
                        >
                          <CustomTextInput
                            icon={link}
                            placeholder="Enter scrape link"
                            inputClassNames=""
                            name={item?.id}
                            onChange={onChangeFields}
                            onFocus={() => onFocus("urls")}
                            disabled={urlsDisabled}
                          />
                          {index !== 0 && (
                            <RemoveIcon
                              onClick={() => removeField(item?.id)}
                              className="ms-3 cursor-pointer"
                            />
                          )}

                          <AddIcon
                            onClick={addField}
                            className="ms-3 cursor-pointer"
                          />
                        </div>
                      ))}
                    </>
                  )}
                </div>

                {docVerified && (
                  <div>
                    <div className="mt-3 mb-3">
                      {fileName}{" "}
                      <DownloadIcon
                        className="ml-3 cursor-pointer"
                        onClick={handleDownload}
                      />
                    </div>
                    <Alert severity={severity}>{docVerificationText}</Alert>
                  </div>
                )}
                <div className="justify-start flex mt-3 items-center mt-5">
                  {docVerified && (
                    <p className="text-sm">
                      Are you sure you want to upload this file?
                    </p>
                  )}

                  <Button
                    variant="contained"
                    className="!ml-3 prim_btn"
                    size="small"
                    onClick={handleSave}
                  >
                    Save
                  </Button>
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={closeModal}
                    className="!ml-3 empty_btn"
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            )}
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

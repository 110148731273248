import * as React from "react";
import { api } from "../../services/api";
import Button from "@mui/material/Button";
import Uploader from "./components/Uploader";
import { getDate } from "../../utils/helper";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import DeleteModal from "../../components/DeleteModal";
import { useSnackbar } from "notistack";
import { createNewConversation } from "../../common/APIs";
import { DataGrid } from "@mui/x-data-grid";
import file_icon from "../../assets/images/file_icon.png";
import CustomHeading from "../../components/CustomHeading";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import AddIcon from "@mui/icons-material/Add";
import { CustomNoRowsOverlay } from "../../components/CustomWidget/components/CustomRowEmpty/CustomNoRowsOverlay";
import { useSelector } from "react-redux";
const columns = [
  { id: "doc_name", label: "File Name", minWidth: 170 },
  {
    id: "created_at",
    label: "Uploaded Date",
  },
];

export default function Knowledgebase() {
  const { enqueueSnackbar } = useSnackbar();
  const [selectedRows, setSelectedRows] = React.useState([]);
  const state = useSelector((state) => state?.auth);

  const [page, setPage] = React.useState(0);
  const [apiComplete, setApiComplete] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [docs, setDocs] = React.useState();
  const [open, setOpen] = React.useState(false);
  const [openDelModal, setDelModal] = React.useState(false);
  const [selectedDoc, setSelectedDoc] = React.useState();

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleOpenDelete = () => setDelModal(true);
  const handleCloseDelete = () => setDelModal(false);

  const getDocuments = async () => {
    setLoading(true);
    try {
      let response = await api.get(`documents/`);

      const filteredResp = response?.data?.filter(
        (item) =>
          item?.bot_id === state?.bot_id &&
          item?.project_id === state?.project_id
      );
      return filteredResp;
    } catch (error) {
      if (error.response && error.response.status === 404) {
        return [];
      } else {
        console.log("Err", error);
      }
    } finally {
      setApiComplete(false);
      setLoading(false);
    }
  };

  const getUrls = async () => {
    setLoading(true);
    try {
      let response = await api.get(`documents/get_websources`);

      const filteredURLS = response?.data?.filter(
        (item) =>
          item?.bot_id === state?.bot_id &&
          item?.project_id === state?.project_id
      );

      return filteredURLS;
    } catch (error) {
      if (error.response && error.response.status === 404) {
        return [];
      } else {
        console.log("Err", error);
      }
    } finally {
      setApiComplete(false);
      setLoading(false);
    }
  };

  const getData = async () => {
    try {
      let [docs, urls] = await Promise.all([getDocuments(), getUrls()]);
      let mergedData = [...docs, ...urls];
      const updatedData = mergedData?.map((item) => {
        return {
          ...item,
          name: item?.doc_name || item?.url_path,
          type: item?.doc_name ? "doc" : "url",
        };
      });
      setDocs(updatedData);
    } catch (error) {
      console.log("Err", error);
    }
  };
  React.useEffect(() => {
    getData();
  }, [state?.bot_id]);

  React.useEffect(() => {
    if (apiComplete) getData();
  }, [apiComplete]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const deleteDoc = async () => {
    setLoading(true);
    try {
      let API_URL =
        selectedDoc?.type === "doc"
          ? `documents/delete/${selectedDoc?.id}`
          : `documents/delete_websource/${selectedDoc?.id}`;

      let response = await api.delete(API_URL);
      if (response?.data?.toString() === "true");
      {
        let obj = {
          user_id: state?.user?.id,
          project_id: state?.project_id,
          bot_id: state?.bot_id,
        };
        await createNewConversation(obj);
        const filtered = docs?.filter((x) => x.id !== selectedDoc?.id);
        setDocs(filtered);
        enqueueSnackbar("Document deleted successfully!", {
          variant: "success",
        });
        handleCloseDelete();
      }
      handleCloseDelete();
    } catch (error) {
      enqueueSnackbar("Document not deleted", { variant: "error" });
    } finally {
      setLoading(false);
    }
  };

  const DRcolumns = [
    {
      field: "name",
      headerName: "Name",
      minWidth: 250,
      renderCell: (params) => (
        <div className="flex justify-start items-center ">
          <img src={file_icon} alt="File_icon" width={14} height={14} />
          {params?.row?.type === "url" ? (
            <a
              href={params?.row?.name}
              target="_blank"
              className="ms-2 underline"
            >
              {params?.row?.name}
            </a>
          ) : (
            <p className="ms-2">{params?.row?.name}</p>
          )}
        </div>
      ),
    },
    { field: "uploaded_at", headerName: "Uploaded Date", minWidth: 250 },

    {
      field: "action",
      headerName: "Action",
      // minWidth: 50,
      renderCell: (params) => (
        <DeleteOutlinedIcon
          className="cursor-pointer"
          aria-label="delete"
          onClick={() => {
            console.log("params", params);
            setSelectedDoc(params.row);
            handleOpenDelete();
          }}
        />
      ),
    },
  ];

  const Search = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: "theme.shape.borderRadius ",
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),

    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "auto",
    },
  }));

  const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: "0 1rem !important",
    height: "100% ",
    position: "absolute ",
    zIndex: "99",
    pointerEvents: "none ",
    display: "flex ",
    alignItems: "center ",
    justifyContent: "center ",
    color: "var(--primary-color)",
  }));

  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "inherit",
    "& .MuiInputBase-input": {
      padding: "theme.spacing(1, 1, 1, 0) !important",
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}) !important`,
      transition: theme.transitions.create("width "),
      width: "100% !important",
      [theme.breakpoints.up("md")]: {
        width: "30ch !important",
      },
    },
  }));
  const handleSelectionChange = (newSelection) => {
    setSelectedRows(newSelection.selectionModel);
    // 'newSelection' contains the selected rows
    console.log("Selected rows:", newSelection.selectionModel);
  };

  const handleAllRowsDelete = () => {
    console.log("records", selectedRows);
  };

  return (
    <>
      <div className="flex justify-between items-center">
        <CustomHeading text="Knowledgebase" />
        <Button
          onClick={handleOpen}
          size="large"
          component="label"
          variant="outlined"
          startIcon={<AddIcon />}
          style={{ color: "black", borderColor: "black", marginLeft: "5px" }}
        >
          Add Content
        </Button>
      </div>
      <div className=" flex justify-end my-3 items-center">
        {/*
         <Search>
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>
          
          <StyledInputBase
            placeholder="Search…"
            inputProps={{ "aria-label": "search" }}
          />
        </Search>
        */}

        {/* <div className="mx-5" onClick={handleAllRowsDelete}>
          <img alt="delete_icon" src={delete_icon} width={20} height={20} />
        </div> */}
      </div>
      {/* <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer sx={{ minHeight: 440, maxHeight: 550 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead style={{ backgroundColor: "red !important" }}>
              <TableRow style={{ backgroundColor: "red !important" }}>
                {columns?.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{
                      minWidth: column.minWidth,
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {docs
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                ?.map((row) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                      <TableCell>
                        <a href={row.doc_path}>{row.doc_name}</a>
                      </TableCell>
                      <TableCell>{getDate(row.created_at)}</TableCell>
                      <TableCell width={50} align="center">
                        <DeleteOutlinedIcon
                          className="cursor-pointer"
                          onClick={() => {
                            setSelectedDoc(row);
                            handleOpenDelete();
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
          {docs?.length === 0 ||
            (docs === undefined && (
              <div className="w-[100%] flex justify-center items-center h-[200px]">
                <h2>No Data</h2>
              </div>
            ))}
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={docs?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper> */}

      <div
        style={{ minHeight: 400, height: 400, maxHeight: 550, width: "100%" }}
      >
        <DataGrid
          loading={loading}
          rows={
            docs?.length !== undefined &&
            docs
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              ?.map((row) => {
                return { ...row, uploaded_at: getDate(row?.created_at) };
              })
          }
          columns={DRcolumns}
          pageSizeOptions={[5, 10]}
          checkboxSelection
          onRowSelectionModelChange={(rows, records) =>
            console.log("row", records)
          }
          slots={{
            noRowsOverlay: CustomNoRowsOverlay,
          }}
        />
      </div>

      <Uploader
        open={open}
        setApiComplete={setApiComplete}
        handleClose={handleClose}
        totalDocs={docs?.length}
      />

      {/* <CustomButton title="Play Ground" className="!mt-3" /> */}

      {openDelModal && (
        <DeleteModal
          loading={loading}
          open={openDelModal}
          handleCloseDelete={handleCloseDelete}
          handleDelete={deleteDoc}
          title="Are you sure you want to delete this document? "
          bodyText="Deleting this knowledgebase also deletes your ongoing conversation and all your training will be lost! "
        />
      )}
    </>
  );
}

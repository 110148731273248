import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getCookie, setCookie } from "../../../utils/storage";
import Cookies from "js-cookie";
import { api } from "../../../services/api";

const initialState = {
  allProjects: getCookie("allProjects")
    ? JSON.parse(getCookie("allProjects"))
    : [],
  allBots: getCookie("allBots") ? JSON.parse(getCookie("allBots")) : [],
  selectedBot: getCookie("selectedBot")
    ? JSON.parse(getCookie("selectedBot"))
    : null,
  project_id: getCookie("project_id") ? getCookie("project_id") : null,
  bot_id: getCookie("bot_id") ? getCookie("bot_id") : null,
  access_token: getCookie("access_token") ? getCookie("access_token") : null,
  refresh_token: getCookie("refresh_token") ? getCookie("refresh_token") : null,
  user: getCookie("user") ? getCookie("user") : null,
  remaining_percent: getCookie("remaining_percent")
    ? getCookie("remaining_percent")
    : 0,
  remaining_tokens: getCookie("remaining_tokens")
    ? getCookie("remaining_tokens")
    : 0,
  user: getCookie("user") ? JSON.parse(getCookie("user")) : null,
};

// Create an async thunk for fetching bots
export const fetchBotsByProjectId = createAsyncThunk(
  "auth/fetchBotsByProjectId",
  async ({ projectId, botId = null }, { getState }) => {
    const response = await api.get(`bots/projects/${projectId}`);
    const data = { response: response?.data, botId };
    return data;
  }
);

export const fetchBotId = createAsyncThunk(
  "auth/fetchBotId",
  async (botId, { getState }) => {
    const response = await api.get(`bots/${botId}`);
    return response?.data;
  }
);

export const getCreditsCount = createAsyncThunk(
  "auth/getCreditsCount",
  async () => {
    try {
      let response = await api.get("dashboard/get_used_tokens");
      let tokens = response?.data;
      return tokens;
    } catch (error) {
      console.error("Error getting credits count", error);
    }
  }
);

export const getCurrentUser = createAsyncThunk(
  "auth/getCurrentUser",
  async () => {
    try {
      let response = await api.get(`/users/me`);
      return response?.data;
    } catch (error) {
      console.error("Error getting credits count", error);
    }
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAllProjects: (state, { payload }) => {
      state.allProjects = payload;
      setCookie("allProjects", payload);
    },
    setProjectId: (state, { payload }) => {
      state.project_id = payload;
      setCookie("project_id", payload);
    },
    setBotId: (state, { payload }) => {
      state.bot_id = payload;
      setCookie("bot_id", payload);
    },
    setAccessToken: (state, { payload }) => {
      state.access_token = payload;
      setCookie("access_token", payload);
    },
    setRefreshToken: (state, { payload }) => {
      state.refresh_token = payload;
      setCookie("refresh_token", payload);
    },
    setUser: (state, { payload }) => {
      state.user = payload;
      setCookie("user", payload);
    },

    setSelectedBot: (state, { payload }) => {
      state.selectedBot = payload;
      setCookie("selectedBot", payload);
    },
    clearAllCookes: (state) => {
      Cookies.remove("access_token");
      Cookies.remove("allProjects");
      Cookies.remove("allBots");
      Cookies.remove("project_id");
      Cookies.remove("bot_id");
      Cookies.remove("refresh_token");
      Cookies.remove("user");
      Cookies.remove("remaining_percent");
      Cookies.remove("remaining_tokens");
      Cookies.remove("selectedBot");

      state.access_token = null;
      state.allProjects = [];
      state.allBots = [];
      state.project_id = null;
      state.bot_id = null;
      state.refresh_token = null;
      state.user = null;
      state.remaining_percent = 0;
      state.remaining_tokens = 0;
      state.selectedBot = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBotsByProjectId.fulfilled, (state, { payload }) => {
        state.allBots = payload?.response;

        let bot;
        if (payload?.botId !== null) {
          bot = payload?.response.find((item) => item.id === payload?.botId);
        } else {
          bot = payload?.response[0];
        }

        state.bot_id = bot?.id;
        state.selectedBot = bot;

        setCookie("allBots", payload?.response);
        setCookie("bot_id", bot?.id);
        setCookie("selectedBot", bot);
      })

      .addCase(fetchBotId.fulfilled, (state, { payload }) => {
        state.selectedBot = payload;
        setCookie("selectedBot", payload[0]);
      })
      .addCase(getCreditsCount.fulfilled, (state, { payload }) => {
        const remainingPercentage =
          ((payload?.total_purchased_tokens - payload?.total_used_tokens) /
            payload?.total_purchased_tokens) *
          100;
        const roundedRemainingPercentage = Math.round(remainingPercentage);
        const remainingTokens =
          payload?.total_purchased_tokens - payload?.total_used_tokens;

        state.remaining_percent = roundedRemainingPercentage;
        state.remaining_tokens = remainingTokens;

        setCookie("remaining_percent", roundedRemainingPercentage);
        setCookie("remaining_tokens", remainingTokens);
      })
      .addCase(getCurrentUser.fulfilled, (state, { payload }) => {
        state.user = payload;
        setCookie("user", payload);
      });
  },
});

// Action creators are generated for each case reducer function
export const {
  setProjectId,
  setBotId,
  setAccessToken,
  setRefreshToken,
  setUser,
  setAllProjects,
  clearAllCookes,
  setSelectedBot,
} = authSlice.actions;

export default authSlice.reducer;

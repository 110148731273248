import React from "react";
import { useParams } from "react-router-dom";
import WidgetSettings from "../Settings/WidgetSettings";

const Chat = () => {
  const params = useParams();

  return (
    <div>
      <WidgetSettings from="conversations" session={params?.sessionId} />
    </div>
  );
};

export default Chat;

import * as React from "react";
import { layoutRoutes } from "../routes/routes";
import { Route, Routes } from "react-router-dom";
import SideBar from "./SideBar";
import { useState, useEffect } from "react";
import Navbar from "./Navbar";
import "./index.css";
import axios from "axios";
import { CHATBOT_CORE_API_URL } from "../constants/URLS";
import { useDispatch, useSelector } from "react-redux";
import {
  getCreditsCount,
  getCurrentUser,
  setAccessToken,
  setRefreshToken,
} from "../pages/Auth/store/authSlice";

export default function AppLayout() {
  const state = useSelector((state) => state.auth);
  const disptach = useDispatch();
  const [active, setActive] = useState(true);

  const toggleSidebar = () => {
    setActive(!active);
  };

  useEffect(() => {
    const runFunction = async () => {
      try {
        const refreshToken = state?.refresh_token;
        const response = await axios.post(
          `${CHATBOT_CORE_API_URL}users/refresh`,
          {
            refresh_token: refreshToken,
          }
        );
        const { access_token, refresh_token } = response.data;

        localStorage.setItem("access_token", access_token);
        localStorage.setItem("refresh_token", refresh_token);
        disptach(setAccessToken(access_token));
        disptach(setRefreshToken(refresh_token));
      } catch (error) {
        console.error("Error refreshing access token:", error);
        // navigate(location.pathname);
      }
    };

    const intervalId = setInterval(runFunction, 14 * 60 * 1000);

    return () => clearInterval(intervalId);
  }, []);

  const getInitialData = async () => {
    try {
      disptach(getCreditsCount());
      disptach(getCurrentUser());
    } catch (error) {
      console.log("Error", error);
    }
  };
  useEffect(() => {
    getInitialData();
  }, []);

  useEffect(() => {
    // Handler to call on window resize
    const handleResize = () => {
      setActive(window.innerWidth <= 768 ? false : true);
    };

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <>
      <div>
        <Navbar />

        <div className="layout_content">
          <br></br>
          <SideBar active={active} toggleSidebar={toggleSidebar} />
          <div className="layout_route">
            <Routes>
              {layoutRoutes?.map((route) => (
                <Route
                  key={route.key}
                  path={route.path}
                  element={route.element}
                />
              ))}
            </Routes>
          </div>
        </div>
      </div>
    </>
  );
}

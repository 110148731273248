import React, { useState, useEffect } from "react";
import CustomWidget from "../../components/CustomWidget";
import { Backdrop, CircularProgress, Grid } from "@mui/material";
import { conv_api } from "../../services/conv_api";
import { useSelector } from "react-redux";
import { customizationSelector } from "../../redux/reducers/customization/customizationSlice";

const Playground = () => {
  const [widgetAppereance, setWidgetAppearance] = useState({
    profile_pic: null,
    initial_message: "Hi, how can I help you?",
    initial_suggested_prompt: "Great! how are you?",
    theme: "light",
    botMsgBackgroundColor: "#707070",
    background_color: "#F3F4F6",
    suggestedMsgBackgroundColor: "#F3F4F6",
    timeTextColor: "#6F7E94",
  });
  const state = useSelector((state) => state?.auth);
  const [loading, setLoading] = useState(true);
  const [conType, setConType] = useState("new-conversation");
  const [sessions, setSessions] = useState([]);
  const [sessionId, setSessionId] = useState();
  const { customization } = useSelector(customizationSelector);

  const getWidgetSettings = async (projectId, botId) => {
    try {
      // const response = await api.get(
      //   `customization/?bot_id=${botId}&project_id=${projectId}`
      // );

      setWidgetAppearance({ ...widgetAppereance, ...customization });
    } catch (error) {
      console.log("Error", error);
    } finally {
    }
  };

  const getConversations = async () => {
    setLoading(true);
    let user_id = state?.user?.id;
    let project_id = state?.project_id;
    let bot_id = state?.bot_id;
    try {
      let response = await conv_api.post(
        `list_conversations?user_id=${user_id}&project_id=${project_id}&bot_id=${bot_id}`
      );

      // Check if response.data exists and is an array
      if (Array.isArray(response?.data) && response.data.length > 0) {
        let lastItem = null;

        // Iterate backwards over the array
        for (let i = response.data.length - 1; i >= 0; i--) {
          const currentItem = response.data[i];

          // Check if `visitor_details` is not an empty object
          if (
            currentItem.visitor_details &&
            Object.keys(currentItem.visitor_details).length === 0
          ) {
            lastItem = currentItem;
            break; // Exit loop when non-empty visitor_details is found
          }
        }

        const sessions = lastItem.sessions;

        if (Array.isArray(sessions) && sessions.length > 0) {
          const lastSession = sessions[sessions.length - 1];
          setSessions(sessions);
          setSessionId(lastSession.session_id);
        } else {
          // Handle case when sessions array is empty
          console.log("Sessions array is empty");
        }
      } else {
        // Handle case when data array is empty or doesn't exist
        console.log("Data array is empty or doesn't exist");
      }
    } catch (error) {
      if (error?.response?.data?.detail === "No conversations found") {
        setSessions([]);
        setSessionId(null);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    async function getDat() {
      setSessionId(null);
      await getConversations();
      await getWidgetSettings(state?.project_id, state?.bot_id);
    }

    getDat();
  }, [state?.bot_id]);

  const handleClickType = async (e, type) => {
    e.preventDefault();
    setConType(type);
  };

  return (
    <div className="h-full flex flex-col items-center ">
      {conType === "train" && (
        <Grid
          container
          spacing={4}
          className="flex justify-center !mt-0 h-[100%]"
        >
          <Grid item xs={12} md={12} lg={12} className="!pt-0 h-[100%] ">
            <CustomWidget
              setSessionId={setSessionId}
              sessionId={null}
              widgetAppereance={widgetAppereance}
              setWidgetAppearance={setWidgetAppearance}
              playground="true"
              type={conType}
            />
          </Grid>
        </Grid>
      )}

      {conType === "new-conversation" && (
        <Grid
          container
          spacing={4}
          className="flex justify-center !mt-0 h-[100%]"
        >
          <Grid item xs={12} md={12} lg={12} className="!pt-0 h-[100%] ">
            <CustomWidget
              sessionId={sessionId}
              setSessionId={setSessionId}
              widgetAppereance={widgetAppereance}
              setWidgetAppearance={setWidgetAppearance}
              playground="true"
              type={conType}
            />
          </Grid>
        </Grid>
      )}

      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
          opacity: 0.4,
        }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default Playground;
